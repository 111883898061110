import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImagesProvider } from 'contexts/ImagesContext';
import Wrapper, { When } from 'components/utils/wrapper';
import Background from 'pages/student/background';
import StudentLogin from 'components/features/student-login';
import StudentSnapshort1 from 'components/features/student-snapshort1';
import StudentSnapshort2 from 'components/features/student-snapshort2';
import StudentSetting from 'components/features/student-setting';
import StudentSummary from 'components/features/student-summary';
import StudentScore from 'components/features/student-score';
import StudentEndSeason from 'components/features/student-end-season';
import StudentComment from 'components/features/student-comment';
import StudentGameComment from 'components/features/student-game-comment';
import StudentLevel from 'components/features/student-level';
import StudentPhoto from 'components/features/student-photo';
import StudentPoint from 'components/features/student-point';
import StudentStudy from 'components/features/student-study';
import * as decisionActions from 'actions/decision';
import { checkIsTokenExpired, monitorAuthenExpire } from 'utils/auth';

const BackgroundComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'pages/student/background',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Background {...props} />
  </ImagesProvider>
);
const StudentLoginComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-login/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentLogin {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentSnapshort2Component = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-snapshort2/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentSnapshort2 {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentSnapshort1Component = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-snapshort1/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentSnapshort1 {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentSettingComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-setting/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentSetting {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentSummaryComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/features/student-summary/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <StudentSummary {...props} />
  </ImagesProvider>
);

const StudentScoreComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/features/student-score/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <StudentScore {...props} />
  </ImagesProvider>
);

const StudentEndSeasonComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/features/student-end-season/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <StudentEndSeason {...props} />
  </ImagesProvider>
);

const StudentCommentComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-comment/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentComment {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentGameCommentComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-game-comment/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentGameComment {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentLevelComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-level/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentLevel {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentPhotoComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-photo/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentPhoto {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentPointComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-point/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentPoint {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const StudentStudyComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/student-study/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <StudentStudy {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);
//const INITAL_ACTIVE_STATE = 'student-login';
const INITAL_ACTIVE_STATE = 'student-snapshort1';
const Decision = (props) => {
  const store = useSelector((state) => state);
  //const { isAuthenSuccess } = store?.auth;
  const { activeState } = store?.decision;
  const [state, setState] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(decisionActions.setActiveState(INITAL_ACTIVE_STATE));
    monitorAuthenExpire(() => {
      dispatch(decisionActions.setActiveState(INITAL_ACTIVE_STATE));
    });
  }, []);

  React.useEffect(() => {
    if (!checkIsTokenExpired() && activeState?.state) {
      setState(activeState?.state);
    } else {
      if (activeState?.state !== INITAL_ACTIVE_STATE) {
        dispatch(decisionActions.setActiveState(INITAL_ACTIVE_STATE));
      } else {
        setState(activeState?.state);
      }
    }
  }, [activeState]);

  return (
    <div root='' className={'app-font-face'}>
      <Wrapper open consider={state}>
        <When condition={'student-login'}>
          <StudentLoginComponent />
        </When>
        <When condition={'student-snapshort1'}>
          <StudentSnapshort1Component />
        </When>
        <When condition={'student-snapshort2'}>
          <StudentSnapshort2Component />
        </When>
        <When condition={'student-setting'}>
          <StudentSettingComponent />
        </When>
        <When condition={'student-summary'}>
          <StudentSummaryComponent />
        </When>
        <When condition={'student-score'}>
          <StudentScoreComponent />
        </When>
        <When condition={'student-end-season'}>
          <StudentEndSeasonComponent />
        </When>
        <When condition={'student-comment'}>
          <StudentCommentComponent />
        </When>
        <When condition={'student-game-comment'}>
          <StudentGameCommentComponent />
        </When>
        <When condition={'student-level'}>
          <StudentLevelComponent />
        </When>
        <When condition={'student-photo'}>
          <StudentPhotoComponent />
        </When>
        <When condition={'student-point'}>
          <StudentPointComponent />
        </When>
        <When condition={'student-study'}>
          <StudentStudyComponent />
        </When>
      </Wrapper>
    </div>
  );
};
export default Decision;
