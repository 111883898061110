import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
//import { useImagesContext } from 'contexts/ImagesContext';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Video = (props) => {
  const { value, type, onAlmostEnded } = props;
  //const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const refVideo = React.useRef(null);
  const [trigger, setTrigger] = useState(false);

  React.useEffect(() => {
    if (refVideo.current) {
      refVideo.current.removeEventListener(
        'timeupdate',
        listenerVidoeAlmostEnded
      );
      refVideo.current.removeEventListener('ended', listenerVidoeEnded);
    }
    setTrigger(false);
    setTimeout(() => {
      setTrigger(true);
    }, 500);
  }, [value]);

  React.useEffect(() => {
    return () => {
      refVideo.current.removeEventListener(
        'timeupdate',
        listenerVidoeAlmostEnded
      );
      refVideo.current.removeEventListener('ended', listenerVidoeEnded);
    };
  }, []);

  const listenerVidoeAlmostEnded = React.useCallback(() => {
    // console.log('listenerVidoeAlmostEnded:', {
    //   currentTime: refVideo.current.currentTime,
    //   duration: refVideo.current.duration - 5,
    // });
    if (refVideo.current.currentTime >= refVideo.current.duration - 5) {
      onAlmostEnded && onAlmostEnded();
    }
  }, [trigger]);

  const listenerVidoeEnded = React.useCallback(() => {
    if (refVideo.current) {
      refVideo.current.webkitExitFullscreen();
      refVideo.current.currentTime = 0;
    }
  }, [trigger]);

  const handleListenVidoeAlmostEnded = React.useCallback(() => {
    refVideo.current.addEventListener('timeupdate', listenerVidoeAlmostEnded);
    refVideo.current.addEventListener('ended', listenerVidoeEnded);
  }, [trigger]);

  return (
    <div className={c('root')}>
      {trigger && type === 'YOUTUBE' ? (
        <iframe
          width='100%'
          height='100%'
          src={`https://www.youtube.com/embed/${value}?rel=0`}
        ></iframe>
      ) : trigger ? (
        <video
          //width='100%'
          height='100%'
          controls
          controlsList='nodownload'
          autoPlay
          playsInline
          style={{
            maxWidth: '100%',
            objectFit: 'fill',
          }}
          //src={'https://www.w3schools.com/html/mov_bbb.mp4'}
          src={value}
          ref={(ref) => {
            if (ref && !refVideo.current) {
              refVideo.current = ref;
              handleListenVidoeAlmostEnded();
            }
          }}
        >
          {/* <source src={value} type='video/mp4'></source> */}
        </video>
      ) : null}
    </div>
  );
};

export default Video;
