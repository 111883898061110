import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import {
//   Search as SearchIcon,
//   DoubleArrow as DoubleArrowIcon,
//   CheckCircle as CheckCircleIcon
// } from '@material-ui/icons';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { getStudentInfo } from 'services/student';
import { useImagesContext } from 'contexts/ImagesContext';
import TextField2 from 'components/fragments/TextField2';
import ButtonNext from 'components/fragments/ButtonNext';
import ButtonLogout from 'components/fragments/ButtonLogout2';
import ButtonMatching from 'components/fragments/ButtonMatching';
import StudentName from 'components/fragments/StudentName';
import ButtonWatch from 'components/fragments/ButtonWatch';
import ButtonQuestion from 'components/fragments/ButtonQuestion';
import NoMatchingAlert from 'components/fragments/NoMatchingAlert';
//import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },

  'img-frame': {
    //width: '80%',
    width: '48vw',
    maxHeight: calvh('24vh'),
    objectFit: 'contain',
  },
  'img-name': {
    width: '46vw',
    maxHeight: calvh('7vh'),
    objectFit: 'contain',
    marginTop: '0.5rem',
  },
  'img-match': {
    width: '30vw',
    maxHeight: calvh('7vh'),
    objectFit: 'contain',
    marginTop: '0.5rem',
  },
  'img-logout': {
    width: '36%',
    paddingLeft: '1rem',
  },
  'img-next': {
    width: '36%',
    paddingRight: '1rem',
  },
  'img-line': {
    width: '85%',
    height: '0.6vh',
    margin: '0.5rem 0px',
  },
  'img-label': {
    width: calvh('25vh'),
    maxHeight: calvh('4.5vh'),
    '@media (min-width:434px)': {
      maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      maxHeight: calvh('4vh'),
    },

    objectFit: 'contain',
    objectPosition: '0%',
    paddingLeft: '1rem',
  },
  'img-01': { width: '90%' },
  'img-watch': {
    width: '12vw',
    maxHeight: calvh('6vh'),
    objectFit: 'contain',
  },
  'img-feild': { width: '12vw', maxHeight: calvh('7vh'), objectFit: 'contain' },
  'text-desc': {
    textAlign: 'center',
    color: '#6c2912',
    textTransform: 'uppercase',
    fontSize: '2rem',
    textShadow: '.01rem .01rem .01rem #000000',
    fontWeight: 'bolder',
    '-webkit-text-stroke': '.018rem #fff',
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
}));

const StudentSnapshort1 = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [openNoMatchingAlert, setOpenNoMatchingAlert] = useState(true);

  const handleOpenNoMatchingAlert = (value) => {
    setOpenNoMatchingAlert(value);
  };

  const compStudentInfo = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container style={{ width: '100%', marginBottom: '.5rem' }}>
          <Grid item xs={6} container alignItems='center'>
            <img
              className={c('img-label')}
              src={images['student-snapshort1-level.png']}
            />
          </Grid>
          <Grid item xs={2}>
            <ButtonQuestion />
          </Grid>
          <Grid item xs={4} container>
            <TextField2
              value={1234}
              styles={{
                padding: '0px 0px 0px 1vw',
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ width: '100%', marginBottom: '.5rem' }}>
          <Grid item xs={6} container alignItems='center'>
            <img
              className={c('img-label')}
              src={images['student-snapshort1-score.png']}
            />
          </Grid>
          <Grid item xs={2}>
            <ButtonQuestion />
          </Grid>
          <Grid item xs={4} container>
            <TextField2
              value={1234}
              styles={{
                padding: '0px 0px 0px 1vw',
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ width: '100%', marginBottom: '.5rem' }}>
          <Grid item xs={6} container alignItems='center'>
            <img
              className={c('img-label')}
              src={images['student-snapshort1-point.png']}
            />
          </Grid>
          <Grid item xs={2}>
            <ButtonWatch />
          </Grid>
          <Grid item xs={4} container>
            <TextField2
              value={1234}
              styles={{
                padding: '0px 0px 0px 1vw',
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ width: '100%', marginBottom: '.5rem' }}>
          <Grid item xs={6} container alignItems='center'>
            <img
              className={c('img-label')}
              src={images['student-snapshort1-photo.png']}
            />
          </Grid>
          <Grid item xs={2}>
            <ButtonWatch />
          </Grid>
          <Grid item xs={4} container>
            <TextField2
              value={134}
              styles={{
                padding: '0px 0px 0px 1vw',
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={c('root')}>
      <Grid
        container
        justifyContent='center'
        alignItems='flex-start'
        alignContent='flex-start'
        style={{ marginTop: '0.5rem', marginBottom: '4rem' }}
      >
        <Grid item xs={8} container justifyContent='center'>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
            }}
          >
            <img
              className={c('img-frame')}
              src={images['student-snapshort1-frame.png']}
            />
            <StudentName
              value={'เม่น'}
              styles={{
                margin: '0.5rem 0px 0px 0px',
              }}
            />
            <ButtonMatching styles={{ padding: '0.5rem 0px 0px 0px' }} />
          </div>
        </Grid>
        <Grid item container justifyContent='center'>
          <img
            className={c('img-line')}
            src={images['student-snapshort1-line.png']}
          />
        </Grid>
        <Grid item container justifyContent='center'>
          {compStudentInfo()}
        </Grid>
        <Grid item container justifyContent='center'>
          <img
            className={c('img-line')}
            style={{ marginTop: '0rem' }}
            src={images['student-snapshort1-line.png']}
          />
        </Grid>
        <Grid item xs={11} container justifyContent='space-between'>
          <ButtonLogout />

          <ButtonNext />
        </Grid>
      </Grid>
      {openNoMatchingAlert ? (
        <NoMatchingAlert onClose={() => handleOpenNoMatchingAlert(false)} />
      ) : null}
    </div>
  );
};

export default StudentSnapshort1;
