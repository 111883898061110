import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert } from 'utils/alert';
import { getIntroduce } from 'services/introduce';
import { useImagesContext } from 'contexts/ImagesContext';
import { useDispatch } from 'react-redux';
import Progress from 'components/fragments/Progress';
import PageNumber from 'components/fragments/PageNumber';
import ButtonOK from 'components/fragments/ButtonOK';
import PointList from 'components/fragments/PointList';
import * as decisionActions from 'actions/decision';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      lineHeight: calvh('3.3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
      '@media (max-width:420px)': {
        fontSize: calvh('2.7vh'),
        lineHeight: calvh('3vh'),
      },
      '@media (max-width:320px)': {
        fontSize: calvh('2.5vh'),
        lineHeight: calvh('2.6vh'),
      },
    },
  },
  'img-intro': {
    height: 'fit-content',
    maxHeight: calvh('70vh'),
    maxWidth: '85vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'container-devider': {
    width: '95%',
    height: calvh('0.36vh'),
    backgroundColor: '#6c2912',
  },
  'text-section2': {
    textAlign: 'center',
    padding: 2,
    flexBasis: '100%',
  },
  'box-section3': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: calvh('1vh'),
    padding: calvh('1vh'),
    border: `${calvh('0.7vh')} solid #00ab0069`,
    borderRadius: '5px',
    backgroundColor: '#f6fff080',
    '& [title]': {
      fontSize: calvh('4vh'),
      textAlign: 'center',
      color: '#009420',
      width: 'fit-content',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& [title-section3-devider]': {
        width: '95%',
        filter: 'drop-shadow(0px 1px 1px #9d9d9db8)',
        height: calvh('0.24vh'),
        backgroundColor: '#009420',
      },
    },
  },
  'text-section3': {
    textAlign: 'center',
    padding: 2,
    color: '#009420',
  },
  'text-section4': {
    textAlign: 'center',
    padding: 2,
    flexBasis: '100%',
  },
}));

const Introduce = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [getIntroduceFlag, setGetIntroduceFlag] = React.useState({});
  const [indroduce, setIndroduce] = React.useState({});
  const [imgagePrincipleLoading, setImgagePrincipleLoading] =
    React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    handleGetIntroduceService();
  }, []);

  const handleClickOk = () => {
    dispatch(decisionActions.setActiveState('principle'));
  };

  const handleGetIntroduceService = (next) => {
    callService(
      getIntroduce,
      {},
      setGetIntroduceFlag,
      (resp) => {
        //dispatch(authActions.setAuthenSuccess());
        setImgagePrincipleLoading(true);
        setIndroduce(resp);
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleError = (error) => {
    errorAlert(error, (result) => {
      if (result.isConfirmed) {
        if (error.response.status === 401) {
          dispatch(decisionActions.setActiveState('login'));
        }
      } else if (result.isDenied) {
      }
    });
  };

  const pendingFlag = React.useMemo(
    () => getIntroduceFlag.request,
    [getIntroduceFlag.request]
  );

  const successFlag = React.useMemo(
    () => getIntroduceFlag.success,
    [getIntroduceFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} container justifyContent='center'>
            <PageNumber page={1} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{ padding: '1vh' }}
          >
            <img
              className={c('img-intro')}
              src={`${config.hostUrl}${indroduce?.imageUri}`}
              onLoad={() => {
                setImgagePrincipleLoading(false);
              }}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{ paddingLeft: '1vw' }}
          >
            <PointList
              list={indroduce?.section1?.list}
              spacing={'0.2rem'}
              styles={{
                alignItems: 'flex-start',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: '10px 0px' }}
          >
            <div className={c('container-devider')} />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            {(indroduce?.section2?.text || []).map((item, idx) => {
              return (
                <div
                  key={'text-section2-' + idx}
                  text-resize=''
                  className={'text' + ' ' + c('text-section2')}
                >
                  {item}
                </div>
              );
            })}
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            <div className={c('box-section3')}>
              <div title='' className={'text'}>
                {indroduce?.section3?.title}
                <div title-section3-devider='' />
              </div>
              {(indroduce?.section3?.text || []).map((item, idx) => {
                return (
                  <div
                    key={'text-section3-' + idx}
                    text-resize=''
                    className={'text' + ' ' + c('text-section3')}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            <div text-resize='' className={'text' + ' ' + c('text-section4')}>
              {indroduce?.section4?.title}
            </div>
            {(indroduce?.section4?.text || []).map((item, idx) => {
              return (
                <div
                  key={'text-section4-' + idx}
                  text-resize=''
                  className={'text' + ' ' + c('text-section4')}
                >
                  {item}
                </div>
              );
            })}
          </Grid> */}

          <Grid item xs={12} container justifyContent='center'>
            <ButtonOK onClick={handleClickOk} />
          </Grid>
        </Grid>
      ) : null}
      {imgagePrincipleLoading ? <Progress /> : null}
    </div>
  );
};

export default Introduce;
